/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ListTopArticlesTags_statistics$ref: FragmentReference;
export type ListTopArticlesTags_statistics = {|
  +top_articles_tags: ?any,
  +$refType: ListTopArticlesTags_statistics$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ListTopArticlesTags_statistics",
  "type": "TeamStatistics",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "top_articles_tags",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '0874ea1fbfae885b498d1699a0e43099';
module.exports = node;
