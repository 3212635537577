/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StackedBarNewslettersSent_statistics$ref: FragmentReference;
export type StackedBarNewslettersSent_statistics = {|
  +number_of_newsletters_delivered: ?number,
  +number_of_newsletters_sent: ?number,
  +$refType: StackedBarNewslettersSent_statistics$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "StackedBarNewslettersSent_statistics",
  "type": "TeamStatistics",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number_of_newsletters_delivered",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number_of_newsletters_sent",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'e81087885faae12f37939722828cac8d';
module.exports = node;
