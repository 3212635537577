/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ListTopMediaTags_statistics$ref: FragmentReference;
export type ListTopMediaTags_statistics = {|
  +top_media_tags: ?any,
  +$refType: ListTopMediaTags_statistics$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ListTopMediaTags_statistics",
  "type": "TeamStatistics",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "top_media_tags",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'd172f4e65e3a1c2f1eae04f9ad43c77d';
module.exports = node;
