/**
 * @flow
 * @relayHash c1d0242716167550cfbd5767876cdf00
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ListTopArticlesSent_statistics$ref = any;
type ListTopArticlesTags_statistics$ref = any;
type NumberArticlesSent_statistics$ref = any;
type NumberExplainersCreated_statistics$ref = any;
type NumberFactChecksCreated_statistics$ref = any;
type NumberPublishedFactChecks_statistics$ref = any;
type StackedBarSearchResultsByType_statistics$ref = any;
type TimelineArticlesCreatedAndUpdated_statistics$ref = any;
type VerticalBarFactChecksByRating_statistics$ref = any;
type VerticalBarFactChecksByRating_team$ref = any;
export type ArticlesDashboardQueryVariables = {|
  teamSlug: string,
  period: string,
  language?: ?string,
|};
export type ArticlesDashboardQueryResponse = {|
  +team: ?{|
    +get_language: ?string,
    +get_languages: ?string,
    +statistics: ?{|
      +$fragmentRefs: ListTopArticlesSent_statistics$ref & ListTopArticlesTags_statistics$ref & NumberArticlesSent_statistics$ref & NumberExplainersCreated_statistics$ref & NumberFactChecksCreated_statistics$ref & NumberPublishedFactChecks_statistics$ref & StackedBarSearchResultsByType_statistics$ref & TimelineArticlesCreatedAndUpdated_statistics$ref & VerticalBarFactChecksByRating_statistics$ref
    |},
    +$fragmentRefs: VerticalBarFactChecksByRating_team$ref,
  |}
|};
export type ArticlesDashboardQuery = {|
  variables: ArticlesDashboardQueryVariables,
  response: ArticlesDashboardQueryResponse,
|};
*/


/*
query ArticlesDashboardQuery(
  $teamSlug: String!
  $period: String!
  $language: String
) {
  team(slug: $teamSlug) {
    get_language
    get_languages
    ...VerticalBarFactChecksByRating_team
    statistics(period: $period, language: $language) {
      ...ListTopArticlesSent_statistics
      ...ListTopArticlesTags_statistics
      ...NumberArticlesSent_statistics
      ...NumberExplainersCreated_statistics
      ...NumberFactChecksCreated_statistics
      ...NumberPublishedFactChecks_statistics
      ...StackedBarSearchResultsByType_statistics
      ...TimelineArticlesCreatedAndUpdated_statistics
      ...VerticalBarFactChecksByRating_statistics
      id
    }
    id
  }
}

fragment VerticalBarFactChecksByRating_team on Team {
  verification_statuses
}

fragment ListTopArticlesSent_statistics on TeamStatistics {
  top_articles_sent
}

fragment ListTopArticlesTags_statistics on TeamStatistics {
  top_articles_tags
}

fragment NumberArticlesSent_statistics on TeamStatistics {
  number_of_articles_sent
}

fragment NumberExplainersCreated_statistics on TeamStatistics {
  number_of_explainers_created
}

fragment NumberFactChecksCreated_statistics on TeamStatistics {
  number_of_fact_checks_created
}

fragment NumberPublishedFactChecks_statistics on TeamStatistics {
  number_of_published_fact_checks
}

fragment StackedBarSearchResultsByType_statistics on TeamStatistics {
  number_of_matched_results_by_article_type
}

fragment TimelineArticlesCreatedAndUpdated_statistics on TeamStatistics {
  number_of_articles_created_by_date
  number_of_articles_updated_by_date
}

fragment VerticalBarFactChecksByRating_statistics on TeamStatistics {
  number_of_fact_checks_by_rating
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "period",
    "type": "String!",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "language",
    "type": "String",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "get_language",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "get_languages",
  "args": null,
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "language",
    "variableName": "language",
    "type": "String"
  },
  {
    "kind": "Variable",
    "name": "period",
    "variableName": "period",
    "type": "String!"
  }
],
v5 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "ArticlesDashboardQuery",
  "id": null,
  "text": "query ArticlesDashboardQuery(\n  $teamSlug: String!\n  $period: String!\n  $language: String\n) {\n  team(slug: $teamSlug) {\n    get_language\n    get_languages\n    ...VerticalBarFactChecksByRating_team\n    statistics(period: $period, language: $language) {\n      ...ListTopArticlesSent_statistics\n      ...ListTopArticlesTags_statistics\n      ...NumberArticlesSent_statistics\n      ...NumberExplainersCreated_statistics\n      ...NumberFactChecksCreated_statistics\n      ...NumberPublishedFactChecks_statistics\n      ...StackedBarSearchResultsByType_statistics\n      ...TimelineArticlesCreatedAndUpdated_statistics\n      ...VerticalBarFactChecksByRating_statistics\n      id\n    }\n    id\n  }\n}\n\nfragment VerticalBarFactChecksByRating_team on Team {\n  verification_statuses\n}\n\nfragment ListTopArticlesSent_statistics on TeamStatistics {\n  top_articles_sent\n}\n\nfragment ListTopArticlesTags_statistics on TeamStatistics {\n  top_articles_tags\n}\n\nfragment NumberArticlesSent_statistics on TeamStatistics {\n  number_of_articles_sent\n}\n\nfragment NumberExplainersCreated_statistics on TeamStatistics {\n  number_of_explainers_created\n}\n\nfragment NumberFactChecksCreated_statistics on TeamStatistics {\n  number_of_fact_checks_created\n}\n\nfragment NumberPublishedFactChecks_statistics on TeamStatistics {\n  number_of_published_fact_checks\n}\n\nfragment StackedBarSearchResultsByType_statistics on TeamStatistics {\n  number_of_matched_results_by_article_type\n}\n\nfragment TimelineArticlesCreatedAndUpdated_statistics on TeamStatistics {\n  number_of_articles_created_by_date\n  number_of_articles_updated_by_date\n}\n\nfragment VerticalBarFactChecksByRating_statistics on TeamStatistics {\n  number_of_fact_checks_by_rating\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "ArticlesDashboardQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "FragmentSpread",
            "name": "VerticalBarFactChecksByRating_team",
            "args": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "statistics",
            "storageKey": null,
            "args": v4,
            "concreteType": "TeamStatistics",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "ListTopArticlesSent_statistics",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "ListTopArticlesTags_statistics",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "NumberArticlesSent_statistics",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "NumberExplainersCreated_statistics",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "NumberFactChecksCreated_statistics",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "NumberPublishedFactChecks_statistics",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "StackedBarSearchResultsByType_statistics",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "TimelineArticlesCreatedAndUpdated_statistics",
                "args": null
              },
              {
                "kind": "FragmentSpread",
                "name": "VerticalBarFactChecksByRating_statistics",
                "args": null
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "ArticlesDashboardQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v3,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verification_statuses",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "statistics",
            "storageKey": null,
            "args": v4,
            "concreteType": "TeamStatistics",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "top_articles_sent",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "top_articles_tags",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number_of_articles_sent",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number_of_explainers_created",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number_of_fact_checks_created",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number_of_published_fact_checks",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number_of_matched_results_by_article_type",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number_of_articles_created_by_date",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number_of_articles_updated_by_date",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "number_of_fact_checks_by_rating",
                "args": null,
                "storageKey": null
              },
              v5
            ]
          },
          v5
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '88487e44a69a698c686b9b36ffe4c3b1';
module.exports = node;
