/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StackedBarSearchResultsByType_statistics$ref: FragmentReference;
export type StackedBarSearchResultsByType_statistics = {|
  +number_of_matched_results_by_article_type: ?any,
  +$refType: StackedBarSearchResultsByType_statistics$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "StackedBarSearchResultsByType_statistics",
  "type": "TeamStatistics",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number_of_matched_results_by_article_type",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '2d8b2fd20cd30a3a534bb7be5c51bdc2';
module.exports = node;
