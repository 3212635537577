/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type VerticalBarFactChecksByRating_statistics$ref: FragmentReference;
export type VerticalBarFactChecksByRating_statistics = {|
  +number_of_fact_checks_by_rating: ?any,
  +$refType: VerticalBarFactChecksByRating_statistics$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "VerticalBarFactChecksByRating_statistics",
  "type": "TeamStatistics",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number_of_fact_checks_by_rating",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bd20257d5ea0c2fd5e992d508db656c9';
module.exports = node;
