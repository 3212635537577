/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type NumberExplainersCreated_statistics$ref: FragmentReference;
export type NumberExplainersCreated_statistics = {|
  +number_of_explainers_created: ?number,
  +$refType: NumberExplainersCreated_statistics$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "NumberExplainersCreated_statistics",
  "type": "TeamStatistics",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number_of_explainers_created",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = 'bfaded5838013e9915a691ca582a7a29';
module.exports = node;
