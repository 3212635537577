/**
 * @flow
 * @relayHash c7f695aa65dd4e298ef1631d2870784d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type NewArticleButton_team$ref = any;
export type DrawerArticlesQueryVariables = {|
  teamSlug: string
|};
export type DrawerArticlesQueryResponse = {|
  +team: ?{|
    +slug: string,
    +factChecksCount: ?number,
    +explainersCount: ?number,
    +publishedCount: ?number,
    +importedCount: ?number,
    +trashCount: ?number,
    +$fragmentRefs: NewArticleButton_team$ref,
  |},
  +me: ?{|
    +is_admin: ?boolean
  |},
|};
export type DrawerArticlesQuery = {|
  variables: DrawerArticlesQueryVariables,
  response: DrawerArticlesQueryResponse,
|};
*/


/*
query DrawerArticlesQuery(
  $teamSlug: String!
) {
  team(slug: $teamSlug) {
    slug
    factChecksCount: articles_count(article_type: "fact-check")
    explainersCount: articles_count(article_type: "explainer")
    publishedCount: articles_count(article_type: "fact-check", report_status: "published")
    importedCount: articles_count(article_type: "fact-check", imported: true)
    trashCount: articles_count(trashed: true)
    ...NewArticleButton_team
    id
  }
  me {
    is_admin
    id
  }
}

fragment NewArticleButton_team on Team {
  ...ClaimFactCheckForm_team
  ...ExplainerForm_team
}

fragment ClaimFactCheckForm_team on Team {
  id
  get_language
  ...ArticleForm_team
}

fragment ExplainerForm_team on Team {
  ...ArticleForm_team
}

fragment ArticleForm_team on Team {
  verification_statuses
  get_language
  get_languages
  slug
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "teamSlug",
    "type": "String!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "teamSlug",
    "type": "String"
  }
],
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "slug",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "Literal",
  "name": "article_type",
  "value": "fact-check",
  "type": "String"
},
v4 = {
  "kind": "ScalarField",
  "alias": "factChecksCount",
  "name": "articles_count",
  "args": [
    v3
  ],
  "storageKey": "articles_count(article_type:\"fact-check\")"
},
v5 = {
  "kind": "ScalarField",
  "alias": "explainersCount",
  "name": "articles_count",
  "args": [
    {
      "kind": "Literal",
      "name": "article_type",
      "value": "explainer",
      "type": "String"
    }
  ],
  "storageKey": "articles_count(article_type:\"explainer\")"
},
v6 = {
  "kind": "ScalarField",
  "alias": "publishedCount",
  "name": "articles_count",
  "args": [
    v3,
    {
      "kind": "Literal",
      "name": "report_status",
      "value": "published",
      "type": "[String]"
    }
  ],
  "storageKey": "articles_count(article_type:\"fact-check\",report_status:\"published\")"
},
v7 = {
  "kind": "ScalarField",
  "alias": "importedCount",
  "name": "articles_count",
  "args": [
    v3,
    {
      "kind": "Literal",
      "name": "imported",
      "value": true,
      "type": "Boolean"
    }
  ],
  "storageKey": "articles_count(article_type:\"fact-check\",imported:true)"
},
v8 = {
  "kind": "ScalarField",
  "alias": "trashCount",
  "name": "articles_count",
  "args": [
    {
      "kind": "Literal",
      "name": "trashed",
      "value": true,
      "type": "Boolean"
    }
  ],
  "storageKey": "articles_count(trashed:true)"
},
v9 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "is_admin",
  "args": null,
  "storageKey": null
},
v10 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "operationKind": "query",
  "name": "DrawerArticlesQuery",
  "id": null,
  "text": "query DrawerArticlesQuery(\n  $teamSlug: String!\n) {\n  team(slug: $teamSlug) {\n    slug\n    factChecksCount: articles_count(article_type: \"fact-check\")\n    explainersCount: articles_count(article_type: \"explainer\")\n    publishedCount: articles_count(article_type: \"fact-check\", report_status: \"published\")\n    importedCount: articles_count(article_type: \"fact-check\", imported: true)\n    trashCount: articles_count(trashed: true)\n    ...NewArticleButton_team\n    id\n  }\n  me {\n    is_admin\n    id\n  }\n}\n\nfragment NewArticleButton_team on Team {\n  ...ClaimFactCheckForm_team\n  ...ExplainerForm_team\n}\n\nfragment ClaimFactCheckForm_team on Team {\n  id\n  get_language\n  ...ArticleForm_team\n}\n\nfragment ExplainerForm_team on Team {\n  ...ArticleForm_team\n}\n\nfragment ArticleForm_team on Team {\n  verification_statuses\n  get_language\n  get_languages\n  slug\n}\n",
  "metadata": {},
  "fragment": {
    "kind": "Fragment",
    "name": "DrawerArticlesQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v4,
          v5,
          v6,
          v7,
          v8,
          {
            "kind": "FragmentSpread",
            "name": "NewArticleButton_team",
            "args": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v9
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "DrawerArticlesQuery",
    "argumentDefinitions": v0,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "team",
        "storageKey": null,
        "args": v1,
        "concreteType": "Team",
        "plural": false,
        "selections": [
          v2,
          v4,
          v5,
          v6,
          v7,
          v8,
          v10,
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_language",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "verification_statuses",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "get_languages",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "me",
        "storageKey": null,
        "args": null,
        "concreteType": "Me",
        "plural": false,
        "selections": [
          v9,
          v10
        ]
      }
    ]
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '26112482478870db92497a2d8fb1b322';
module.exports = node;
