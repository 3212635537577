/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type NumberSubscribers_statistics$ref: FragmentReference;
export type NumberSubscribers_statistics = {|
  +number_of_subscribers: ?number,
  +number_of_new_subscribers: ?number,
  +$refType: NumberSubscribers_statistics$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "NumberSubscribers_statistics",
  "type": "TeamStatistics",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number_of_subscribers",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "number_of_new_subscribers",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '9b3ba89d001d0d5512d990bf44ed7527';
module.exports = node;
