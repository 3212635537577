/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ListTopArticlesSent_statistics$ref: FragmentReference;
export type ListTopArticlesSent_statistics = {|
  +top_articles_sent: ?any,
  +$refType: ListTopArticlesSent_statistics$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "ListTopArticlesSent_statistics",
  "type": "TeamStatistics",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "top_articles_sent",
      "args": null,
      "storageKey": null
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '6f061a9c4258a0b2b0f52876bd68fa0e';
module.exports = node;
