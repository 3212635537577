/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SensitiveContentMenuButton_projectMedia$ref: FragmentReference;
export type SensitiveContentMenuButton_projectMedia = {|
  +dbid: ?number,
  +show_warning_cover: ?boolean,
  +dynamic_annotation_flag: ?{|
    +id: string,
    +data: ?any,
  |},
  +$refType: SensitiveContentMenuButton_projectMedia$ref,
|};
*/


const node/*: ConcreteFragment*/ = {
  "kind": "Fragment",
  "name": "SensitiveContentMenuButton_projectMedia",
  "type": "ProjectMedia",
  "metadata": null,
  "argumentDefinitions": [],
  "selections": [
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "dbid",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "ScalarField",
      "alias": null,
      "name": "show_warning_cover",
      "args": null,
      "storageKey": null
    },
    {
      "kind": "LinkedField",
      "alias": null,
      "name": "dynamic_annotation_flag",
      "storageKey": null,
      "args": null,
      "concreteType": "Dynamic",
      "plural": false,
      "selections": [
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "id",
          "args": null,
          "storageKey": null
        },
        {
          "kind": "ScalarField",
          "alias": null,
          "name": "data",
          "args": null,
          "storageKey": null
        }
      ]
    }
  ]
};
// prettier-ignore
(node/*: any*/).hash = '29806f52b3177e0e780abbad463a53d9';
module.exports = node;
